<template>
  <div class="fixed bottom-0 left-0 z-50 w-full h-16 border-t bg-gray-700 border-gray-600"></div>
  <div class="fixed bottom-4 left-0 z-50 w-full h-16 border-t bg-gray-700 border-gray-600">
    <div class="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
      <router-link to="/"
                   class="inline-flex flex-col items-center justify-center px-5 border-x hover:bg-gray-800 group border-gray-600">
        <svg class="w-5 h-5 mb-2 text-gray-400 group-hover:text-blue-500" aria-hidden="true"
             xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path
            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
        </svg>
        <span class="text-sm text-gray-400 group-hover:text-blue-500">Home</span>
      </router-link>
      <router-link to="/dishes"
                   class="inline-flex flex-col items-center justify-center px-5 border-r hover:bg-gray-800 group border-gray-600">
        <svg class="w-5 h-5 mb-2 text-gray-400 group-hover:text-blue-500" aria-hidden="true"
             xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
          <path
            d="M19 0H1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1ZM2 6v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6H2Zm11 3a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0h2a1 1 0 0 1 2 0v1Z"></path>
        </svg>
        <span class="text-sm text-gray-400 group-hover:text-blue-500">Dishes</span>
      </router-link>
      <router-link to="/PriceSearch"
                   class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-800 group border-gray-600">

        <svg class="w-5 h-5 mb-2 text-gray-400 group-hover:text-blue-500" xmlns="http://www.w3.org/2000/svg" width="20"
             height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M19 19L15 15M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
            stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="text-sm text-gray-400 group-hover:text-blue-500">Search</span>
      </router-link>
      <router-link to="/profile"
                   class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-800 group border-x border-gray-600">
        <svg class="w-5 h-5 mb-2 text-gray-400 group-hover:text-blue-500" aria-hidden="true"
             xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path
            d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
        </svg>
        <span class="text-sm text-gray-400 group-hover:text-blue-500">Profile</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      showNavbar: false
    };
  },
  mounted() {
    let token = localStorage.getItem("app-token");
    if (token) {
      this.showNavbar = true;
    }
  }
};
</script>
